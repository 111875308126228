/* eslint-disable no-undef */
import '../App.css'
import React, { useEffect, useMemo, useState } from 'react';
import tg from '../assets/communication.png';
import web from '../assets/world-wide-web.png';
import x from '../assets/twitter.png';
import logo from '../assets/PUMPLOGO.png';
import Countdown from 'react-countdown';
import { bsc } from 'wagmi/chains'
import copy from '../assets/copy.png';
import user from '../assets/profile-user.png';
import plane from '../assets/paper-plane.png';
import ref from '../assets/network.png';
import rewards from '../assets/medal-ribbon.png';
import bnbLogo from '../assets/bnb-bnb-logo.png';
import gift from '../assets/gift.png';
import podium from '../assets/podium.png';
import idPic from '../assets/id.png';
import lady from '../assets/lady-removebg-preview (2).png';
import { Web3 } from "web3";
import pic1 from '../assets/bb.png';
import pic2 from '../assets/checkdex.png';
import pic3 from '../assets/dex-screener.png';
import pic4 from '../assets/solslugs.png';
import at from '../assets/at.png';
import pump from '../assets/pump.png';
import ms from '../assets/ms.png';
import dog from '../assets/dog.jpeg';
import cartoon from '../assets/cartoon.png';
import word from '../assets/word.png';
import gallery from '../assets/images (2).png';
import cat from '../assets/cat.jpeg';
import searchImg from '../assets/search-interface-symbol.png';
import axios from 'axios';
import sc from '../assets/sol.png';
import yt from '../assets/youtube.png';
import fire from '../assets/passion.gif';
import burn from '../assets/fireplace.gif';
import sol from '../assets/solana.png';
import { Link, useNavigate } from "react-router-dom";

import {
	SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
	LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import '../buffer-polyfill';


const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const ytL = () => {
	window.open("https://youtube.com/@pumpfound");
}

const tweet = () => {
	window.open("https://x.com/pumpfound");
}

const tgL = () => {
	window.open("https://t.me/pumpfound");
}

const homeLink = () => {
	window.open("http://www.pumpfound.com");
}

const link1 = () => {
	window.open("https://bonkbot.io/");
}

const link2 = () => {
	window.open("https://www.checkdex.xyz/");
}

const link3 = () => {
	window.open("https://dexscreener.com/");
}

const link4 = () => {
	window.open("https://solslugs.com/");
}

function Home() {
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const web3 = new Web3("https://necessary-dry-breeze.bsc.quiknode.pro/d40c20a7d806cdc17501e901d0cf8147c30046de/");


	const [_totalSupply, settotalSupply] = useState(0);
	const [statusError, setstatusError] = useState("");
	const [statusLoading, setstatusLoading] = useState("");
	const [_owner, set_owner] = useState("");
	const [myNFTWallet, setmyNFTWallet] = useState(0);
	const [_public_mint_status, set_public_mint_status] = useState("");
	const [_navbarOpen, set_navbarOpen] = useState(0);
	const [_connected, setConnected] = useState(false);
	const [_registerDiv, set_registerDiv] = useState(0);
	const [_dashboardDiv, set_dashboardDiv] = useState(0);
	const [_name, set_name] = useState("");
	const [_success1, setSuccessMsg1] = useState("");
	const [_loading1, setLoadingMsg1] = useState("");
	const [_ErrorMsg1, setErrorMsg1] = useState("");
	const [success, setsuccess] = useState("");
	const [nfts, setNfts] = useState([]);
	const [selectedContractIndex, setSelectedContractIndex] = useState(0);
	const [userInfo, setUserInfo] = useState(null);
	const [userNFTs, setUserNFTs] = useState([]);
	const [nft1Collection, setUserInfoForNFT1] = useState([1, 2, 3]);
	const [nft2Collection, setUserInfoForNFT2] = useState([1, 2]);
	const [nft3Collection, setUserInfoForNFT3] = useState([1, 2, 3]);
	const [_viewUser, set_getUser] = useState("");
	const [_getRefferalCount, set_getRefferalCount] = useState(0);
	const [_getIdByAddress, set_getIdByAddress] = useState(0);

	const [_totalUsers, set_totalUsers] = useState("");
	const [_totalCommissions, setTotalCommissions] = useState(0);
	const [_totalCommissions2, setTotalCommissions2] = useState(0);
	const [_totalCommissions3, setTotalCommissions3] = useState(0);

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [walletAddress, setWalletAddress] = useState(null);
	const [walletObject, setwalletObject] = useState(null);
	const [_signature, setSignature] = useState(false);
	const [_isPhantom, setIsPhantom] = useState(false);
	const [response, setResponse] = useState('');
	const [fetching, setFetching] = useState([]);
	const [search, setSearch] = useState([]);
	const [_fireClicked, set_fireClicked] = useState(0);
	const [_search, set_search] = useState('');
	const [_searchResponse, set_searchResponse] = useState('');
	const [_searchReady, setSearchReady] = useState(0);
	const [_noData, setNoData] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [_loaded, setLoaded] = useState(0);
	const [_pageNo, setPageNo] = useState(1);
	const [_o, setO] = useState(0);
	const [_hitFilter, set_hitFilter] = useState(false);
	const [_loader, set_loader] = useState(0);
	const [_dataLength, setDataLength] = useState(0);

	const navigate = useNavigate();

	async function closeNav() {
		set_navbarOpen(0);
	}

	async function navbarOpen() {
		set_navbarOpen(1);
	}

	async function fetchTokenDetails(tokenId, contractAddress) {
		// Call the getTokenDetails function from your contract ABI
		const tokenDetails = await getTokenDetails();
		// Do something with the token details, e.g., display them in UI
	}

	useEffect(() => {

		getDataFromServer(_pageNo);

		if (_search === '') {
			setSearchReady(0);
		}

		if (_searchResponse === '') {
			setSearchReady(0);
		}

	}, [_search, _searchReady, _pageNo]);

	async function registerDivOpen() {
		set_registerDiv(1);
	}

	async function dashboardDivOpen() {
		set_dashboardDiv(1);
	}

	async function fireClicked() {
		set_fireClicked(1);
	}

	async function closeFireDiv() {
		set_fireClicked(0);
	}


	async function closediv() {
		set_registerDiv(0);
		set_dashboardDiv(0);
	}
	const fetchDetails = async () => {
		try {

			// Trigger the compilation first
			const mintResponse = await axios.post('https://pumpserver2.vercel.app/api/_fetchDetails',
				{
					name: _Name,
					memeTicker: _MemeTicker,
					description: _Description,
					website: _Website,
					twitter: _Twitter,
					telegram: _Telegram,
					pump: _Pump,
					moonshot: _Moonshot,
					solScan: _SolScan

				}

			);

		} catch (err) {
			console.log(err);
		}
	};

	const connectWallet = async () => {
		try {
			const { solana } = window;
			if (solana) {
				// Connect to the user's wallet and get their public key
				const response = await solana.connect();
				setWalletAddress(response.publicKey.toString());
				setwalletObject(response);

				// Sign a message using the user's wallet
				const message = Uint8Array.from([...new TextEncoder().encode('Welcome to PumpFound')]);
				const signedMessage = await solana.signMessage(message);

				// Check if the message property exists before converting it to an array
				let serializedMessage;
				if ('message' in signedMessage) {
					serializedMessage = Array.from(signedMessage.message);
				} else {
					serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
				}

				// Add the signature and serialized message to the response object
				response.signature = signedMessage.signature;
				response.serializedMessage = serializedMessage;

				console.log("Connected with public key:", response.publicKey.toString());
				console.log("Signature:", signedMessage.signature.toString());
				console.log("Serialized Message:", serializedMessage);

			}

		} catch (err) {
			console.log(err);
		}
	};

	const _disconnectWallet = async () => {
		try {
			//  await walletObject.disconnect();
			console.log("Disconnected from wallet");
			setWalletAddress(null);
			setwalletObject(null);
			window.location.reload(true);

		} catch {
			console.log(err);
		}
	};

	const shortenAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-3)}`;
	};

	const sendMoney = async () => {
		try {

			const connection = new Connection('https://api.devnet.solana.com', 'confirmed');

			const price = 0.0775 * LAMPORTS_PER_SOL;

			const transaction = new Transaction().add(
				SystemProgram.transfer({
					fromPubkey: walletObject.publicKey,
					toPubkey: new PublicKey('7SFwqFDjoQuNujWAYCbYiBguZ2oc5iTK7Fdi1DTSfeHs'),
					lamports: price,
				}),
			);


			//Source Account
			transaction.feePayer = walletObject.publicKey;
			transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

			let signature;

			signature = await window.solana.signAndSendTransaction(transaction);

			console.log('Transaction sent:', signature);

			if (signature != null) {
				setResponse("Transaction Successful");
				setSignature(true);

			} else {
				setResponse("Please check again");
			}

		} catch (err) {
			console.log(err);
		}
	}

	const getDataFromServer = async (pageNo) => {
		const response = await axios.get('https://pumpserver2.vercel.app/api/getallfriends');

		console.log("response :" + response.data);

		console.log("Current Page No : " + pageNo);

		setActivePage(pageNo);

		let itemDataArray = [];


		let y = 10;
		let o = ((pageNo - 1) * y);

		let loaded = 0;
		let selected = 0;
		let percentage = 0;
		let totalRunner = 0;
		let selectedPages = 0;

		setDataLength(response.data.length);

		for (let x = o; x < response.data.length && x < pageNo * 10; x++) {
			set_loader(1);

			loaded++;
			percentage = loaded * 10;
			//console.log("percentage : " + percentage);
			setLoaded(percentage);

			itemDataArray.push(
				response.data[x]
			)
			console.log("response.data[x] :" + response.data[x]);

		}


		setFetching(itemDataArray)
	};

	const pageIndexer = () => {
		let pageIndexes = [];
		console.log("_dataLength%10 :" + (Number(_dataLength / 10 % 10)));
		console.log("Number(_dataLength)/2 :" + Number(_dataLength) / 2);

		if (!_hitFilter) {
			for (let x = _o; x < (((10 + Number(_o)) > (Number(_dataLength) / 10)) ? ((Number(_dataLength) / 10) % 10) : (10 + Number(_o))); x++) {
				const pageNum = x + 1;
				console.log(" Number(_dataLength)" + Number(_dataLength));
				pageIndexes.push(
					<div
						key={x}
						onClick={() => getDataFromServer(pageNum)}
						className={pageNum === activePage ? 'eachNumber active' : 'eachNumber'}
					>
						{pageNum}
					</div>
				);
			}
		} else {

			var i = 0;

			if (Number(_selectedPages) > 10) {
				i = 10;
			} else {
				i = Number(_selectedPages);
			}
			for (let x = _o; x < (i + Number(_o)); x++) {
				const pageNum = x + 1;
				pageIndexes.push(
					<div
						key={x}
						onClick={() => getDataFromServer(pageNum)}
						className={pageNum === activePage ? 'pageBtns active' : 'pageBtns'}
					>
						{pageNum}
					</div>
				);
			}
		}
		return pageIndexes;
	}

	const arrowClicked = () => {

		if (_hitFilter) {
			var count = Number(_o) + 10;
			if (Number(_selectedPages) >= count) {
				setO(Number(_o) + 10);
			}
		} else {
			var count = Number(_o) + 10;
			if (count < Number(_dataLength / 10)) {
				setO(Number(_o) + 10);

				console.log("Arrow Clicked : " + Number(_o) + 10);
			}
		}

	}

	const arrowLast = () => {
		setO((Number(_dataLength / 2)) + 1);
	}

	const arrowFirst = () => {
		setO(0);
		getDataFromServer(1);
	}

	const arrowPrevious = () => {

		var count = Number(_o) - 10;
		if (count >= 0) {
			setO(Number(_o) - 10);
		}
	}


	const getSearch = async () => {
		try {

			// Trigger the compilation first
			const search = await axios.post('https://pumpserver2.vercel.app/api/search',
				{
					searchVar: _search,
				}

			);

			console.log("SEARCH.Data : " + JSON.stringify(search.data));
			set_searchResponse(search.data)

			await new Promise(resolve => setTimeout(resolve, 1000));
			if (search.data.message == "No documents found") {
				setSearchReady(0);
				console.log("MATCHED!!!");
				setNoData(true);
			} else {
				setSearchReady(1);
				console.log("NOT MATCHED!!!");
				setNoData(false);
			}

		} catch (err) {
			console.log(err);
		}
	};

	const profileDetails = async (_NAME, _IMAGE, _MEMETICKER, _TIME, _DESCRIPTION, _PUMP, _MOONSHOT,
		_TELEGRAM, _TWITTER, _WEBSITE, _SOLSCAN) => {

		navigate((`/profile`), {

			state: {
				name: _NAME,
				image: _IMAGE,
				memeTicker: _MEMETICKER,
				time: _TIME,
				description: _DESCRIPTION,
				pump: _PUMP,
				moonshot: _MOONSHOT,
				telegram: _TELEGRAM,
				twitter: _TWITTER,
				website: _WEBSITE,
				solScan: _SOLSCAN
			},
		});

	};

	function convertUnixTimestampToDateString(timestamp) {
		const date = new Date(timestamp * 1000); // Convert to milliseconds
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const days = date.getDate();
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const monthName = months[month - 1];
		const hours = date.getHours();
		const minutes = "0" + date.getMinutes();
		const formattedTime = hours + ':' + minutes.substr(-2);

		const now = new Date();
		const currentYear = now.getFullYear();

		let displayedYear = "";
		if (year >= currentYear) {
			displayedYear = String(year).slice(-2);
		} else {
			displayedYear = `20${currentYear % 100}`;
		}

		return `${days}-${monthName}-${displayedYear} ${formattedTime}`;
	}

	return (
		<div className="allWrap">
			<div className="light">

				<div className="headers">
					<div className="headers2">
						<div className="logo">
							<img className="logoPic" src={logo} onClick={() => window.location.href = '/'} />
						</div>
						<div className="right">
							<div className="icons">
								<div className="socialIcon">
									<img onClick={ytL} src={yt} />
								</div>
								<div className="socialIcon">
									<img onClick={tgL} src={tg} />
								</div>
								<div className="socialIcon">
									<img onClick={tweet} src={x} />
								</div>
								<div className="socialIcon">
									<img onClick={homeLink} src={web} />
								</div>
							</div>

							{!walletAddress ? (
								<button className="connectBtn" onClick={connectWallet}>Connect Wallet</button>
							) : (
								<button className="connectBtn" onClick={_disconnectWallet}>{shortenAddress(walletAddress)}</button>
							)}

						</div>
					</div>
				</div>

				<div className='networks'>
					<img src={pic1} onClick={link1} />
					<img src={pic2} onClick={link2} />
					<img src={pic3} onClick={link3} />
					<img src={pic4} onClick={link4} />
				</div>

				<div className='contact'></div>

				<div className='memeB'>
					<div>- MEMEBOARD -</div>
				</div>

				<div className='detailsTableMain'>
					<button class='memeBtnMain' onClick={() => window.location.href = 'register'}> &gt; LIST MEME &lt; </button>
				</div>

				<div className='search'>
					<input className='searchInput'
						placeholder="search"
						name="search"
						value={_search}
						onChange={event => set_search(event.target.value)}
					/>
					<img onClick={getSearch} src={searchImg} />
				</div>

				<div className='tables'>

					{_noData ?

						<div className='nodata'>
							NO DATA
						</div> :

						<>					{_searchReady > 0 ?
							<>
								{_searchResponse.map((entry, index) => (
									<div className='eachBoxMain-1'>
										<img id="fire" onClick={fireClicked} src={fire} />
										<div className='fireDivMain'><img id="fire2" onClick={fireClicked} src={fire} /></div>
										<div className='eachBoxMain' key={index}>
											<div className='eachBox' onClick={() => profileDetails
												(
													entry.NAME,
													entry.IMAGE,
													entry.MEMETICKER,
													entry.TIME,
													entry.DESCRIPTION,
													entry.PUMP,
													entry.MOONSHOT,
													entry.TELEGRAM,
													entry.TWITTER,
													entry.WEBSITE

												)}>
												<div className='imgDiv'> <img src={entry.IMAGE} alt={`Image for ${entry.NAME}`} /></div>
												<div className='detailsMain'>
													<div className='boxHeader'>
														<div className='boxT'>{entry.NAME} (TICKER {entry.MEMETICKER})</div>
														<div className='date'>{convertUnixTimestampToDateString(entry.TIME)}</div>
													</div>

													<div>
														<div className='description'>
															{entry.DESCRIPTION}
														</div>
													</div>
												</div>
											</div>

											<div className="iconsBox">
												<a href={`https://solscan.io/${entry.SOLSCAN}`} target="_blank" rel="noopener noreferrer">
													<img src={sc} />
												</a>
												<a href={`https://pump.fun/${entry.PUMP}`} target="_blank" rel="noopener noreferrer">
													<img src={pump} />
												</a>
												<a href={`https://t.me/${entry.MOONSHOT}`} target="_blank" rel="noopener noreferrer">
													<img src={ms} />
												</a>
												<a href={`https://t.me/${entry.TELEGRAM}`} target="_blank" rel="noopener noreferrer">
													<img src={tg} />
												</a>
												<a href={`https://x.com/${entry.TWITTER}`} target="_blank" rel="noopener noreferrer">
													<img src={x} />
												</a>
												<a href={`https://${entry.WEBSITE}`} target="_blank" rel="noopener noreferrer">
													<img src={web} />
												</a>
											</div>
										</div>
									</div>
								))}
							</>
							:
							<>
								{fetching.map((entry, index) => (
									<div className='eachBoxMain-1'>
										<img id="fire" src={fire} />
										<div className='fireDivMain'><img id="fire2" src={fire} /></div>
										<div className='eachBoxMain' key={index}>
											<div className='eachBox' onClick={() => profileDetails
												(
													entry.NAME,
													entry.IMAGE,
													entry.MEMETICKER,
													entry.TIME,
													entry.DESCRIPTION,
													entry.PUMP,
													entry.MOONSHOT,
													entry.TELEGRAM,
													entry.TWITTER,
													entry.WEBSITE,
													entry.SOLSCAN

												)}>
												<div className='imgDiv'> <img src={entry.IMAGE} alt={`Image for ${entry.NAME}`} /></div>
												<div className='detailsMain'>
													<div className='boxHeader'>
														<div className='boxT'>{entry.NAME} (TICKER {entry.MEMETICKER})</div>
														<div className='date'>{convertUnixTimestampToDateString(entry.TIME)}</div>
													</div>

													<div>
														<div className='description'>
															{entry.DESCRIPTION}
														</div>
													</div>
												</div>
											</div>

											<div className="iconsBox">
												{entry.SOLSCAN && (
													<a href={entry.SOLSCAN} target="_blank" rel="noopener noreferrer">
														<img src={sc} alt="Solscan" />
													</a>
												)}
												{entry.PUMP && (
													<a href={entry.PUMP} target="_blank" rel="noopener noreferrer">
														<img src={pump} alt="Pump" />
													</a>
												)}
												{entry.MOONSHOT && (
													<a href={entry.MOONSHOT} target="_blank" rel="noopener noreferrer">
														<img src={ms} alt="Moonshot" />
													</a>
												)}
												{entry.TELEGRAM && (
													<a href={entry.TELEGRAM} target="_blank" rel="noopener noreferrer">
														<img src={tg} alt="Telegram" />
													</a>
												)}
												{entry.TWITTER && (
													<a href={entry.TWITTER} target="_blank" rel="noopener noreferrer">
														<img src={x} alt="Twitter" />
													</a>
												)}
												{entry.WEBSITE && (
													<a href={entry.WEBSITE} target="_blank" rel="noopener noreferrer">
														<img src={web} alt="Website" />
													</a>
												)}
											</div>


										</div>
									</div>
								))}
							</>
						}
						</>}
				</div>

				{_noData ?
					<div></div> :
					<div className='pages'>
						{/*<div onClick={() => arrowFirst()} className='EachNumber2'>First</div>
*/}
						<div><div onClick={() => arrowPrevious()} id="symbol">&lt;</div></div>

						<div className='eachNumMain'>{pageIndexer()}</div>

						<div><div onClick={() => arrowClicked()} id="symbol">&gt;</div></div>
						{/*<div onClick={() => arrowLast()} className='EachNumber2'>Last</div>*/}

					</div>
				}

				{_fireClicked > 0 ?
					<div className="popUpBackground" id="popUpBackground">
						<div className="popUpDiv show">
							<div className="close" id="closePopUp" onClick={closeFireDiv}>X</div>
							<div className="popUpT">refresh meme</div>
							<p className="desPopUp">The ranking can be updated at any time, and your favorite MEME will appear first on the homepage every time it is fresh</p>
							<div className="inputSection">
								<div className="input1">
									<input type="text" placeholder='1'></input>
									<div>SOL <img className='inputImgs2' src={sol} /></div>
								</div>
								<div className="input2">
									<input type="text" placeholder='636239795298'></input>
									<div>BURN <img className='inputImgs' src={burn} /></div>
								</div>
							</div>
							<div className='gB'>GET BURN &gt;</div>
							<button className='fireBtn'>PAY</button>
						</div>
					</div> : null}

			</div>


			<div className="footer">Copyright @ 2024 pumpfound.com limited</div>
		</div >
	);
};

export default Home;